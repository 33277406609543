@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Montserrat', sans-serif;
    background-image: url("/assets/img/background.jpg"); 
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-blend-mode: overlay;
}
